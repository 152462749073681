<template>
	<el-config-provider :locale="locale">
		<div id="app">
			<MuyuNavigationModel v-if="show" :userInfo="$store.state.userInfo || {}" @outLogin="outLogin"></MuyuNavigationModel>
			<router-view />
		</div>
	</el-config-provider>
</template>
<script>
import {
	ElConfigProvider
} from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import MuyuNavigationModel from 'muyu-navigation-model/packages/src/NavigationModel/NavigationModel';

export default {
	components: {
		[ElConfigProvider.name]: ElConfigProvider,
		MuyuNavigationModel
	},
	created() {
		this.show = false;
		this.setInfo()
		this.$store.commit('setType')
	},
	watch: {
		'$route': {
			handler(to) {
				if (!to.name || to.name == 'login') {
					this.show = false
				} else {
					this.show = true
				}
			},
			immediate: true
		}
	},
	data() {
		return {
			locale: zhCn,
			show: false,
			userInfo: null
		}
	},
	methods: {
		setInfo() {
			let userInfo = window.localStorage.getItem('userInfo') || {};
			if (userInfo) {
				try {
					this.userInfo = JSON.parse(userInfo);
					this.userInfo.avatar = this.userInfo.headerImg;
					this.userInfo.r_name = this.userInfo.nickName;
					this.userInfo.tradeName = this.userInfo.userName
				} catch {
					this.userInfo = {};
					this.userInfo.avatar = ''
				}
			}
			this.$store.commit('localInfo', this.userInfo)
		},
		outLogin() {
			let userInfo = window.localStorage.getItem('userInfo') || '';
			let id = ''
			if(userInfo){
              id = JSON.parse(userInfo).xfCmsId
			}
			window.location = window.location.href = window.location.protocol + '//' + window.location.host + `/${id}`;
			window.localStorage.removeItem('token');
			window.localStorage.removeItem('userInfo');
		}
	}
}
</script>
<style lang="scss" scoped>
* {
	margin: 0;
	padding: 0;
}

#app {
	height: 100%;
}
/deep/ .el-dialog__header{
	border-bottom: 1px solid #f5f5f5;
}
/deep/ .el-form--inline .el-form-item{
	margin-right: 10px;
}

/deep/ .el-input {
	.el-input__inner{
		font-size: 12px;
		border-radius: 0;
		
	}
	.el-input__inner::placeholder{
		font-size: 12px;
		
	}
	
}
</style>
