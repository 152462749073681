import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css';
import myplugin from '../plugin/plugin.js';
import '@/assets/base.scss'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import flotCode from './views/commMan/qecode.vue'
import * as request from '../plugin/request'
import navlid from './unit/components/nvalid.vue';


  // 给 vue 实例挂载内部对象，例如：
  // app.config.globalProperties.$XModal = VXETable.modal
  // app.config.globalProperties.$XPrint = VXETable.print
  // app.config.globalProperties.$XSaveFile = VXETable.saveFile
  // app.config.globalProperties.$XReadFile = VXETable.readFile



const app = createApp(App);
app.config.globalProperties.$request = request;
app.component('flotCode',flotCode)
app.component('Navlid',navlid)
app.use(VXETable)
app.use(ElementPlus);
app.use(myplugin);
app.use(store).use(router).mount('#app')
