import { createStore } from 'vuex'
import * as request from '../../plugin/request'
export default createStore({
  state: {
    userInfo: {},
    type:''
  },
  mutations: {

    localInfo(state,data){
      state.userInfo = data
    },
    setType(state,item){
      let type = window.localStorage.getItem('type') || '';
      if(item){
        state.type = item
      } else if(type){
        state.type = type
      }
    }
  },
  actions: {
    getUserInfo({state}, data) {
      window.localStorage.setItem('token', data.token)
      request.HttpGet('/admin/getUser').then(res => {
        if (res.data) {
          state.userInfo = res.data;
          state.userInfo.avatar = state.userInfo.headerImg;
          state.userInfo.r_name = state.userInfo.nickName;
          state.userInfo.tradeName = state.userInfo.userName;
          window.localStorage.setItem('userInfo', JSON.stringify(res.data));
        }
      })
    },
  },
  modules: {
  }
})
