
import confirm from '@/components/confirm/index'
import myInput from '@/components/my-input.vue';
import myselect from '@/components/myselect.vue'
import * as plugin from '../plugin/request';
import myButton from '@/unit/components/myButton.vue';
import NavBar from '@/unit/components/navBar.vue'
import pop from '@/unit/components/pop.vue'
import { Close, ArrowDown} from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';

const message = (msg, type = 'success') => {
	return ElNotification({
		title: '成功',
		message: msg,
		type
	})
}
let timer = false;
const debounce = (fn) => {
	if (timer) return false;
	timer = true;
	fn();
	setTimeout(() => {
		timer = false;
	}, 500);
}
let obj = {
	...plugin,
	confirm,
	message,
	debounce
};
export default {
	install(Vue) {
		Vue.component('my-input', myInput);
		Vue.component('myButton', myButton);
		Vue.component('myselect',myselect)
		Vue.component('Close', Close);
		Vue.component('ArrowDown',ArrowDown)
		Vue.provide('plugin', obj);
		Vue.component('pop', pop);
		Vue.component('NavBar',NavBar)
	}
}
