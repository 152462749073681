<template>
  <div class="my-input" @mouseover="mouseover" @mouseleave="mouseleave">
    <div class="title">
      <slot name="pre"></slot>
    </div>
    <input
      type=""
      id=""
      class="input_inners"
      :value="modelValue"
      :placeholder="placeholder"
      @input="emited"
    />
    <el-icon :size="15">
      <close class="myicon" v-if="show" @click="clear" />
    </el-icon>
    <div class="append">
        <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  setup(props, ctx) {
    let show = ref(false);
    function emited(e) {
      let value = e.target.value;
      ctx.emit("update:modelValue", value);
      value ? show.value = true : show.value = false
    }
    // 清空
    function clear() {
      ctx.emit("update:modelValue", "");
      show.value = false;
    }

    // 显示隐藏清空按钮
    function mouseover() {
      if (props.modelValue) {
        show.value = true;
      }
    }
    function mouseleave() {
      show.value = false;
    }
    return {
      emited,
      clear,
      mouseover,
      mouseleave,
      show,
    };
  },
});
</script>

<style lang="scss" scoped>
.my-input {
  height: 32px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  // padding: 0 5px;
  box-sizing: border-box;
  background: #ffffffff;
  flex-shrink: 0;
  .title {
    padding: 0 5px;
    color: #7c7d80;
  }

  .input_inners {
    width: 100px;
    height: 100%;
    flex: 1;
    height: 100%;
    background: none;
    padding-left: 5px;

    &::placeholder {
      color: #bdbfc2;
    }
  }

  .myicon {
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 5px;
  }
  .append{
    flex:1;
    // background: red;
    box-sizing: border-box;
   /deep/ .mybutton{
      border-right: none;
    }
  }
}
</style>
