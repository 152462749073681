import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/nav',
    name: 'nav',
    meta: {
      title: '导航页'
    },
    component: () => import('@/views/nav')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index'),
    redirect: '/index/department',
    children: [
      {
        path: 'department',
        name: 'department',
        meta: {
          title: '部门管理'
        },
        component: () => import('@/views/department')
      },
      {
        path: 'employee',
        name: 'employee',
        meta: {
          title: '员工管理'
        },
        component: () => import('@/views/employee')
      },
      {
        path: 'position',
        name: 'position',
        meta: {
          title: '职位管理'
        },
        component: () => import('@/views/position')
      },
      {
        path: 'billRunWater',
        name: 'billRunWater',
        meta: {
          title: '店铺流水'
        },
        component: () => import('@/views/runwater/billRunWater')
      },
      {
        path: 'billRunWater/waterDetail',
        name: 'waterDetail',
        meta: {
          title: '流水明细'
        },
        component: () => import('@/views/runwater/waterDetail')
      },
      {
        path: 'marketbillRunWater',
        name: 'marketbillRunWater',
        meta: {
          title: '市场流水'
        },
        component: () => import('../views/marketrunwater/billRunWater.vue')
      },
      {
        path: 'marketbillRunWater/merchangeRunWater',
        name: 'marketmerchangeRunWater',
        meta: {
          title: '商户列表'
        },
        component: () => import('@/views/marketrunwater/merchangeRunWater')
      },
      {
        path: 'marketbillRunWater/waterDetail',
        name: 'marketwaterDetail',
        meta: {
          title: '商户流水明细'
        },
        component: () => import('@/views/marketrunwater/waterDetail')
      },
      {
        path: 'tenementbillRunWater',
        name: 'tenementbillRunWater',
        meta: {
          title: '物业流水'
        },
        component: () => import('../views/tenementrunwater/billRunWater.vue')
      },
      {
        path: 'tenementbillRunWater/waterDetail',
        name: 'tenementwaterDetail',
        meta: {
          title: '流水明细'
        },
        component: () => import('@/views/tenementrunwater/waterDetail')
      },
      {
        path: 'tenementbillRunWater/runningWater',
        name: 'runningWater',
        meta: {
          title: '企业流水'
        },
        component: () => import('@/views/tenementrunwater/runningWater')
      },
      //____________________
      {
        path: 'community',
        name: 'community',
        meta: {
          title: '小区'
        },
        component: () => import('../views/commMan/index'),
        redirect: '/index/community/commMan',
        children: [
          {
            path: 'commMan',
            name: 'commMan',
            meta: {
              title: '小区管理'
            },
            component: () => import('../views/commMan/communityMan/index.vue')
          },
          {
            path: 'dataView',
            name: 'dataView',
            meta: {
              title: '数据总览'
            },
            component: () => import('../views/commMan/dataView/index.vue'),
          },
          {
            path: 'publicNum',
            name: 'publicNum',
            meta: {
              title: '公众号绑定'
            },
            component: () => import('../views/commMan/publicNum/index.vue'),
          },
          {
            path: 'dataView/dataDetail',
            name: 'dataDetail',
            meta: {
              title: '详情'
            },
            component: () => import('../views/commMan/dataView/detail.vue'),
          }
        ]
      }
    ]
  }
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

})
export default router
