<template>
  <div class="navBar">
    <div class="navBarlist">
      <div class="title">{{ name }}</div>
      <div
          @click="tabclick(item, index)"
          :class="[router ? 'routeritem' : 'tabItem', (act == item.path || act == index) && router ? 'act tabs' : (act == item.path || act == index) && !router ? 'act' : '', list.length == 1 ? 'act' : '']"
          v-for="(item, index) in list"
          :key="index"
      >{{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:'NavBar',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    name: {
      type: String,
      default: '物业管理'
    },
    router: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (this.router) {
          this.act = to.path;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      act: 0,
    };
  },
  created() {
 
  },
  methods: {
    tabclick(data, index) {
      if (this.router) {
        data.path ?
            this.select(data.path)
            : ''
      } else {
        this.change(index);
      }
    },
    select(path) {
      if (path == this.act) {
        return;
      }
      this.act = path;
      this.$router.push({
        path
      });
    },
    change(e) {
      this.act = e;
      this.$emit('change', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.navBar {
  width: 100%;
  height: 57px;
  // margin-bottom: 20px;
  font-size: 12px;
  border-bottom: 1px solid #DEDEDE;
  position: sticky;
  left: 0;
  top: 0;
  box-sizing: border-box;
  user-select: none;
  background-color: #FFFFFF;
  z-index: 99;

  .label {
    width: 100%;
    padding-left: 40px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 21px 0 14px;
  }

  .navBarlist {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    bottom: -1px;

    .title {
      min-width: 140px;
      font-size: 14px;
      color: #333333;
      text-align: center;
      margin-bottom: 7px;
    }

    .routeritem {
      padding: 0 10px;
      height: 37px;
      text-align: center;
      line-height: 32px;
      border: 1px solid #dedede;
      z-index: 9;
      border-left: none;
      box-sizing: border-box;
      background: #ffffff;
      cursor: pointer;

      &:nth-child(2) {
        border-left: 1px solid #dedede;
      }

      &:last-child {
        border-right: 1px solid #dedede;
      }
    }

    .tabItem {
      padding: 0 10px;
      height: 37px;
      text-align: center;
      line-height: 32px;
      z-index: 9;
      border-left: none;
      border-bottom: 1px solid #dedede;
      box-sizing: border-box;
      background: #ffffff;
      cursor: pointer;
    }

    .act {
      color: #3d7eff;
      border-bottom: none;
      border-top: 2px solid #3d7eff;
      border-right: 1px solid #dedede;
      border-left: 1px solid #dedede;
    }

    .tabs {
      border-left: none;
    }
  }
}
</style>
