import { createApp } from 'vue';
import confirm from '@/components/confirm/confirm.vue';
export default function (tips = '提示内容',title="提示"){
  
    let dom = document.createElement('div');
    document.body.appendChild(dom);
    let instance = createApp(confirm);
    instance.mount(dom)
    let {data} =  instance._instance
    data.title = title;
    data.tips = tips;
    data.show = true;
    setTimeout(() => {
        instance._instance.data.boxshow = true
    }, 10);
    return instance._instance.ctx.isconfirm()
	.then(res =>{
		return Promise.resolve(res)
	})
	.catch(err => {
		return Promise.reject(err)
	})

}