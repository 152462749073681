<template>
	<div class="mybutton" :class="[{disabled}, type,left?'mybtn-left':'',right?'mybtn-right':'',$slots.preImage?'paddings':'padding']" @click.stop="!disabled && emit">
		<slot name="preImage"></slot>
		<slot>{{title}}</slot>
		<slot name="suffImage"></slot>
	</div>
</template>

<script>
export default {
	name: 'myButton',
	props: {
		image: {
			type: String
		},
		title: {
			type: String,
			default: '按钮'
		},
		type: {
			type: String,
			default: 'default'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		accessId: {
			type: [String, Number]
		},
		left:{
			type:Boolean,
			default:false
		},
		right:{
			type:Boolean,
			default:false
		},
		padding:{
			type:Boolean,
			default:true
		}
	},
	data() {
		return {

		};
	},
	created(){
	},
	methods: {
		debounce(){
			this.emit()
        //   this.$debounce()
		},
		emit() {
			this.$emit('click');
			// if (this.disabled) return false
			// if (this.accessId) {
			// 	let accessId = window.localStorage.getItem('accessId');
			// 	if (accessId.includes(this.accessId)) {
			// 		this.$emit('click');
			// 	} else {
			// 		this.$plugin.notifyError('暂无权限')
			// 	}
			// } else {
			// 	this.$emit('click');
			// }
		}
	}
};
</script>

<style lang="scss" scoped>
.mybutton {
	min-width: 66px;
	height: 32px;
	background: #ffffff;
	border: 1px solid #dedede;
	box-sizing: border-box;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: top;
	padding: 0;
	cursor: pointer;
	font-size: 12px;
	text-align: center;
	user-select: none;
	white-space: none;
	/deep/ img {
		margin: 0 5px 0 10px;
		vertical-align: middle;
		width: 18px;
		height: 18px;
	}

	.mybtn {
		flex: 1;
		text-align: center;
		user-select: none;
	}
	/deep/ img {
		width: 22px;
		height: 22px;
	}
	&:hover {
		background: #f9f9f9;
		color: #3d7eff;
		.mybtn {
			color: #3d7eff;
		}
	}
}
.padding{
	padding: 0 15px;
	box-sizing: border-box;
}
.paddings{
	padding-right: 15px;
}
.default {
	color: #000;
}

.primary {
	color: #3d7eff;
}
.error{
	color: red;
}
.disabled {
	cursor: not-allowed;
	background: #f4f4f4;
}
.mybtn-left{
	margin-left: 15px;
}
.mybtn-right{
	margin-right: 15px;
}
</style>
