import axios from 'axios';
import {
  ElNotification
} from 'element-plus';

const request = (data = {}) => {
  return axios(data).then((res) => {
    if (res.status >= 400) {
      throw new Error('请求错误');
    } else {
      if (data.responseType == 'blob') {
        return res.data;
      }
      if (res.data.code == 0) {
        return res.data;
      }
      if (res.data.code == 401 || res.data.data.reload) {
        let userInfo = window.localStorage.getItem('userInfo') || '';
        let id = ''
        if (userInfo) {
          id = JSON.parse(userInfo).xfCmsId
        }
        window.location = window.location.href = window.location.protocol + '//' + window.location.host + `/${id}`;
      }
      console.log(res.data);
      ElNotification({
        title: '错误',
        message: res.data.msg || '未知错误',
        type: 'error'
      });
      throw new Error(res);
    }
  }).catch((error) => {
    throw new Error(error);
  });
};

export const HttpGet = async (path = '', data = {}, type = {}) => {
  const token = window.localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  };
  let succeed = await request({
    method: 'GET',
    url: process.env.VUE_APP_URL + path,
    headers,
    params: data,
    responseType: type.responseType || 'json'
  });
  return succeed;
};

export const HttpPost = async (path = '', data = {}, type = {}) => {
  const token = window.localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  };

  let succeed = await request({
    method: 'POST',
    url: process.env.VUE_APP_URL + path,
    headers,
    data,
    responseType: type.responseType || 'json'
  });
  return succeed;
};
