<template>
<div class="flot-code" v-if="show">
  <div class="codeclose" @click="show=false">x</div>
  <div class="properpub">
    <div class="title">物业公众号</div>
    <div>
      <img src="@/assets/img/code.jpg" alt="">
    </div>
  </div>
  <div class="miapp">
    <div class="title">业主小程序</div>
    <div>
      <img src="@/assets/img/code.jpg" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      client: {
        left: 0,
        top: 0
      }
    }
  },
  methods: {
    move() {
      //   document.querySelector('.flot-code').addEventListener('mousemove',e=>{
      //       this.client = {left:`${e.screenX}px`,top:`${e.screenY}px`}
      //   })
      //   this.client
      //   console.log(e);
    },
    leave() {
      document.querySelector('.flot-code').removeEventListener('mousemove')
    }
  }
}
</script>

<style lang="scss">
.flot-code {
    width: 150px;
    // height: 200px;
    //    background: ;
    border: 1px solid rgb(240, 238, 238);
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: #FFFFFFFF;
    z-index: 999999;
    transition: all 0.5s;
    .codeclose {
        position: absolute;
        top: -15px;
        right: -10px;
        font-size: 30px;
        cursor: pointer;
    }
    .properpub, .miapp {
      padding: 5px;
      box-sizing: border-box;
      .title {
        margin-bottom: 10px;
      }
      img {
        width: 100px;
        height: 100px;
        display: block;
        margin: 0 auto;
      }
    }
}
</style>
